import React, { useState, useEffect, useContext } from 'react';
import { useOidcUser } from '@axa-fr/react-oidc';
import {
  TextHeader,
  CreateTaskDataContainer,
  DataColumn,
  SectionHeader,
  HeaderContainer,
  SelectStyle,
  TextAreaStyle,
  SubmitButtonContainer,
  SubmitButton,
  CancelButton,
  SaveButton,
  CompleteButton,
  InputStyle,
  ActivitiesTextHeader,
  SpinnerButton,
  ErrorMessage,
  HiFriendModal,
  HiFriendContent,
  HiFriendDismissButton,
  CardPreviewContainer,
  SubmitButtonTooltipContainer,
  CompleteButtonTooltipContainer,
  CallContainer,
} from './styles';

import { parseErrorMessage } from 'Helpers/parseApiErrorMessage';
import PropTypes from 'prop-types';
import AddActivity from '../AddActivity/AddActivity';
import ActivityList from '../ActivityList/ActivityList';
import { MainIconBarContext } from 'Tasking_Contexts/MainIconBarContext';
import { useForm } from 'react-hook-form';
import Modal from 'Tasking_Components/CompleteTaskModal/CompleteActivityModal';
import Spinner from 'Components/Spinner/Spinner';
import { formatDateEditTask } from 'Tasking_Helpers/formatDate';
import { checkUserPermission } from 'Tasking_Helpers/checkUserPermission';
import { TaskSelectedContext } from 'Tasking_Contexts/TaskSelectedContext';
import useAssignOptions from 'Tasking_Hooks/useAssignOptions';
import useCreateTask from 'Tasking_Hooks/useCreateTask';
import useEditTask from 'Tasking_Hooks/useEditTask';
import useCompleteTask from 'Tasking_Hooks/useCompleteTask';
import useEnumLists from 'Tasking_Hooks/useEnumLists';
import useTaskDetails from 'Tasking_Hooks/useTaskDetails';
import useMemberInfo from 'Tasking_Hooks/useMemberInfo';
import useGetEnrolledNgm from 'Tasking_Hooks/useGetEnrolledNgm';
import { Route, Routes, matchPath, useLocation, useNavigate, useParams } from 'react-router-dom';
import { ActivitySelectedContext } from 'Tasking_Contexts/ActivitySelectedContext';
import GreetingCard from 'Tasking_Components/GreetingCard/GreetingCard';
import useThriveStatus from 'Tasking_Hooks/useThriveStatus';
import CallLog from 'Tasking_Components/CallLog/CallLog';
import { Tooltip } from '@mui/material';
import urlcat from 'urlcat';
import {
  ENROLLED,
  ENROLLED_TASKS,
  ENROLLED_TASKS_EDIT_ACTIVITY,
  ENROLLED_TASKS_EDIT_ACTIVITY_VIEW,
  NONENROLLED_TASKS,
  NONENROLLED_TASKS_EDIT,
  NONENROLLED_TASKS_EDIT_ACTIVITY,
} from '../../../initialization/Routes/RoutesConfig';
import PeersCallButton from 'Tasking_Components/PeersCall/PeersCallButton';
import { CallLogModuleStore } from 'Tasking_Components/CallLog/CallLogModuleStore';
import { screeningNamesShortened } from 'Tasking_Components/Screenings/ScreeningForm/config';

const CreateTask = ({ setNewTaskClicked, isSavedTask, setSavedTaskClicked, completed, memberTaskInfo, ngm }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { userId, ngmId, orgId, activityGuid, taskGuid } = useParams();
  const [viewActivity, setViewActivity] = useState();
  const [values, setValues] = useState('');
  const [modal, setModal] = useState();
  const [init, setInit] = useState(false);
  const [hiFriendMessage, displayHiFriendMessage] = useState(false);
  const [isOnboardTask, setIsOnboardTask] = useState(false);
  const [currentSelectedTaskType, setCurrentSelectedTaskType] = useState('ENROLLMENT');
  const [currentSelectedTaskSubType, setCurrentSelectedTaskSubType] = useState(selectedTask?.taskSubType ?? 'SDOH');
  const [completeTaskData, setCompleteTaskData] = useState();
  const { setShowMainIcons } = useContext(MainIconBarContext);
  const { enums } = useEnumLists();
  const { oidcUser } = useOidcUser();
  const { assignOptions } = useAssignOptions();
  const { memberInfo, retrieveMemberInfo } = useMemberInfo(oidcUser?.sub);
  const { status: thriveStatus, getThriveStatus } = useThriveStatus();
  const { taskSaved, isPending, errorMessage: createTaskErrorMessage, createTask } = useCreateTask();
  const { isEditTaskPending, errorMessage: editTaskErrorMessage, editTask } = useEditTask();
  const { taskCompletedRequestCompleted, isCompleteTaskPending, taskErrorMessage, completeTask } = useCompleteTask();
  const { selectedTask, setSelectedTask } = useContext(TaskSelectedContext);
  const { selectedActivity, setSelectedActivity } = useContext(ActivitySelectedContext);
  const [showSelectedActivity, setShowSelectedActivity] = useState(false);
  const [userHasGreetingCardPermission, setUserHasGreetingCardPermission] = useState(false);
  const savedTaskClicked = isSavedTask || taskGuid;
  const organizationId = ngm ? memberTaskInfo.organizationId : memberTaskInfo.activeOrganizationId;
  const callLogStore = CallLogModuleStore.Instance().Use();

  const {
    taskDetail,
    isPending: isTaskDetailsPending,
    retrieveTask,
  } = ngm
    ? useTaskDetails(memberTaskInfo?.organizationId, selectedTask?.taskGuid ?? taskGuid)
    : useTaskDetails(memberTaskInfo?.activeOrganizationId, selectedTask?.taskGuid ?? taskGuid);

  const [assignedTo, setAssignedTo] = useState();
  const { enrolledMemberData, isGetPending: isEnrolledNgmPending } = ngm
    ? useGetEnrolledNgm(memberTaskInfo?.organizationId, memberTaskInfo?.id, init)
    : '';
  const [callLogInfo, setCallLogInfo] = useState();
  const defaultDueDate = taskDetail
    ? formatDateEditTask(taskDetail.dueDateUtc)
    : selectedTask
    ? formatDateEditTask(selectedTask?.dueDateUtc)
    : '';

  const defaultNotes = taskDetail ? taskDetail.notes : selectedTask ? selectedTask.notes : ``;

  const { register, handleSubmit, getValues, setValue, formState } = useForm({
    defaultValues: { TaskType: 'ENROLLMENT', DueDate: defaultDueDate },
  });

  useEffect(() => {
    getThriveStatus(userId);
  }, []);

  useEffect(() => {
    setCurrentSelectedTaskSubType(selectedTask?.taskSubType ?? 'SDOH');
  }, [selectedTask]);

  useEffect(() => {
    const id = ngm ? memberTaskInfo?.organizationId : memberTaskInfo?.activeOrganizationId;
    retrieveTask(id, selectedTask?.taskGuid ?? taskGuid);
  }, [isEditTaskPending]);

  useEffect(() => {
    if (oidcUser && !memberInfo) {
      retrieveMemberInfo(oidcUser.sub);
    }
  }, [oidcUser]);

  useEffect(() => {
    if (taskSaved) {
      navigate(
        ngm
          ? urlcat(NONENROLLED_TASKS_EDIT_ACTIVITY, { orgId: orgId, ngmId: ngmId, taskGuid: taskSaved ?? taskGuid })
          : urlcat(ENROLLED_TASKS_EDIT_ACTIVITY, { userId: userId, taskGuid: taskSaved ?? taskGuid }),
        { state: { showActivity: true } },
      );
      setSavedTaskClicked(true);
      setNewTaskClicked(false);
    }
  }, [isPending]);

  useEffect(() => {
    checkUserCardPermissions();
    if (savedTaskClicked) {
      setSelectedTask({ ...selectedTask, notes: taskDetail?.notes });
      setAssignedTo(taskDetail?.assignedToUserId);
      setValue('Assign', taskDetail?.assignedToUserId);
      const dueDateValue = !getValues().DueDate || getValues().DueDate.includes('NaN') ? null : getValues().DueDate;
      setValue('DueDate', dueDateValue ?? formatDateEditTask(taskDetail?.dueDateUtc) ?? defaultDueDate);
      const notesValue = !getValues().Notes || getValues().Notes ? null : getValues().Notes;
      setValue('Notes', notesValue ?? defaultNotes);
    } else {
      setAssignedTo(oidcUser?.sub);
      setValue('Assign', oidcUser?.sub);
    }
  }, [oidcUser, taskDetail]);

  useEffect(() => {
    if (!selectedTask && taskGuid) {
      setSelectedTask({ taskGuid });
    }
    if (taskDetail || selectedTask) {
      if (location?.state?.showActivity || location?.pathname?.includes('activity')) {
        const match = matchPath({ path: ENROLLED_TASKS_EDIT_ACTIVITY_VIEW }, window.location.pathname);
        const taskActivityGuid = match?.params?.activityGuid;
        const currentActivityGuid = activityGuid || taskActivityGuid;
        const currentActivity =
          currentActivityGuid && taskDetail
            ? taskDetail?.activities?.find((x) => x.guid == currentActivityGuid)
            : currentActivityGuid && selectedTask
            ? selectedTask?.activities?.find((x) => x.guid == currentActivityGuid)
            : undefined;
        if ((!currentActivityGuid || !currentActivity) && (completed || selectedTask?.status === 'COMPLETE')) {
          navigate(
            ngm
              ? urlcat(NONENROLLED_TASKS_EDIT, {
                  orgId: orgId,
                  ngmId: ngmId,
                  taskGuid: selectedTask?.taskGuid ?? taskGuid,
                })
              : urlcat(ENROLLED_TASKS_EDIT, { userId: userId, taskGuid: selectedTask?.taskGuid ?? taskGuid }),
          );
          setViewActivity(false);
        } else {
          setSelectedActivity(currentActivity ?? selectedActivity);
          setShowSelectedActivity(true);
        }
      }
    }
  }, [taskDetail]);

  useEffect(() => {
    if (selectedActivity || showSelectedActivity) {
      const match = matchPath({ path: ENROLLED_TASKS_EDIT_ACTIVITY_VIEW }, window.location.pathname);
      const taskActivityGuid = match?.params?.activityGuid;
      if (activityGuid ?? taskActivityGuid) {
        setViewActivity(true);
      }
      setShowSelectedActivity(false);
    }
  }, [selectedActivity, showSelectedActivity]);

  useEffect(() => {
    if (taskCompletedRequestCompleted) {
      if (isOnboardTask) {
        if (!isEnrolledNgmPending) {
          if (enrolledMemberData?.userId > 0) {
            setSavedTaskClicked(false);
            setNewTaskClicked(false);
            setSelectedTask();
            navigate(urlcat(ENROLLED, { userId: enrolledMemberData?.userId }));
          } else {
            displayHiFriendMessage(true);
          }
        }
      } else {
        setSavedTaskClicked(false);
        setNewTaskClicked(false);
        setSelectedActivity();
        setSelectedTask();
        navigate(
          ngm ? urlcat(NONENROLLED_TASKS, { orgId: orgId, ngmId: ngmId }) : urlcat(ENROLLED_TASKS, { userId: userId }),
        );
      }
    }
  }, [isCompleteTaskPending, isEnrolledNgmPending]);

  useEffect(() => {
    if (completeTaskData && !isEditTaskPending) {
      if (ngm && completeTaskData?.CloseReason == 'SUCCESSFULONBOARD') {
        setInit(true);
        setIsOnboardTask(true);
      }
      completeTask(completeTaskData, organizationId, selectedTask.taskGuid ?? taskGuid);
      setCompleteTaskData();
    }
  }, [isEditTaskPending]);

  const onSubmit = (e) => {
    if (savedTaskClicked) {
      navigate(
        ngm
          ? urlcat(NONENROLLED_TASKS_EDIT_ACTIVITY, {
              orgId: orgId,
              ngmId: ngmId,
              taskGuid: selectedTask?.taskGuid ?? taskGuid,
            })
          : urlcat(ENROLLED_TASKS_EDIT_ACTIVITY, { userId: userId, taskGuid: selectedTask?.taskGuid ?? taskGuid }),
        { state: { showActivity: true } },
      );
      setShowMainIcons(true);
      return null;
    }
    if (ngm) {
      setValues(e);
      const valueForCreateTask = {
        organizationId: memberTaskInfo.organizationId,
        ngmId: memberTaskInfo.id,
        assignedToUserId: e.Assign,
        taskType: e.TaskType,
        taskSubType: e.TaskSubtype,
        dueDateUtc: e.DueDate,
        notes: e.Notes,
      };

      createTask(valueForCreateTask);
    } else {
      setValues(e);
      const valueForCreateTask = {
        organizationId: memberTaskInfo.activeOrganizationId,
        profileId: memberTaskInfo.activeProfileId,
        assignedToUserId: e.Assign,
        taskType: e.TaskType,
        taskSubType: e.TaskSubtype,
        dueDateUtc: e.DueDate,
        notes: e.Notes,
      };
      createTask(valueForCreateTask);
      setCallLogInfo({ ...callLogInfo, taskData: { dueDateUtc: new Date(e.DueDate).toISOString() } });
    }
  };

  const parseError = (errorMessage) => {
    if (errorMessage.length > 0) {
      return parseErrorMessage(errorMessage);
    }
  };

  const save = async () => {
    const e = getValues();
    if (!e.DueDate) return;
    if (ngm) {
      setValues(e);
      const valueForCreateTask = {
        organizationId: memberTaskInfo.organizationId,
        ngmId: memberTaskInfo.id,
        assignedToUserId: e.Assign,
        dueDateUtc: e.DueDate,
        notes: e.Notes,
      };
      editTask(valueForCreateTask, memberTaskInfo.organizationId, selectedTask.taskGuid ?? taskGuid);
    } else {
      setValues(e);
      const valueForCreateTask = {
        organizationId: memberTaskInfo.activeOrganizationId,
        profileId: memberTaskInfo.activeProfileId,
        assignedToUserId: e.Assign,
        dueDateUtc: e.DueDate,
        notes: e.Notes,
      };
      editTask(valueForCreateTask, memberTaskInfo.activeOrganizationId, selectedTask.taskGuid ?? taskGuid);
      setCallLogInfo({ ...callLogInfo, taskData: { dueDateUtc: new Date(e.DueDate).toISOString() } });
    }
  };

  const cancel = () => {
    setNewTaskClicked(false);
    setSavedTaskClicked(false);
    setShowMainIcons(true);
    setSelectedTask();
    setCallLogInfo();
    callLogStore.reset();
    if (location?.pathname?.includes('tasks')) {
      navigate(
        ngm ? urlcat(NONENROLLED_TASKS, { orgId: orgId, ngmId: ngmId }) : urlcat(ENROLLED_TASKS, { userId: userId }),
        { state: { showTaskScreen: true } },
      );
    }
  };

  const hiFriendDimissal = () => {
    setSavedTaskClicked(false);
    setNewTaskClicked(false);
    setSelectedTask();
  };

  const completeClicked = () => {
    if (!getValues().DueDate) return;
    setModal(true);
  };

  const closeReasonPicked = async (data) => {
    setCompleteTaskData(data);
    // save changes before completing task
    await save();
  };

  const checkUserCardPermissions = async () => {
    setUserHasGreetingCardPermission(await checkUserPermission('resource.cards.admin'));
  };

  const shouldTaskBeDisplayed = (code) => {
    return code !== 'CARD' || (code === 'CARD' && userHasGreetingCardPermission && thriveStatus?.thriveStatus);
  };

  const displayButtons = () => {
    if (!completed && selectedTask?.status !== 'COMPLETE') {
      return (
        <SubmitButtonContainer>
          {isEditTaskPending ? (
            <SpinnerButton saveButtonSpinner>
              <Spinner />
            </SpinnerButton>
          ) : (
            <SaveButton showSave={savedTaskClicked} onClick={save} value="Save" data-testid="SaveButton">
              Save
            </SaveButton>
          )}

          <Tooltip
            arrow
            placement="top"
            title={
              callLogInfo?.callLogState?.completeTaskButtonDisabled
                ? callLogInfo?.callLogState?.completeTaskToolTip
                : ''
            }
          >
            <CompleteButtonTooltipContainer showcomplete={savedTaskClicked}>
              <CompleteButton
                onClick={completeClicked}
                showcomplete={savedTaskClicked}
                disabled={callLogInfo?.callLogState?.completeTaskButtonDisabled}
                value="Complete"
                variant="contained"
                disableElevation
                data-testid="CompleteButton"
              >
                Complete
              </CompleteButton>
            </CompleteButtonTooltipContainer>
          </Tooltip>
          {isPending ? (
            <SpinnerButton>
              <Spinner />
            </SpinnerButton>
          ) : (
            <Tooltip
              arrow
              placement="top"
              title={
                callLogInfo?.callLogState?.addActivityButtonDisabled ? callLogInfo?.callLogState?.activityToolTip : ''
              }
            >
              <SubmitButtonTooltipContainer showcomplete={savedTaskClicked}>
                <SubmitButton
                  data-testid="SubmitButton"
                  name={'submit'}
                  disabled={callLogInfo?.callLogState?.addActivityButtonDisabled}
                  variant="contained"
                  disableElevation
                  type="submit"
                  showcomplete={savedTaskClicked}
                >
                  {savedTaskClicked ? 'Add Activity' : 'Save and Start'}
                </SubmitButton>
              </SubmitButtonTooltipContainer>
            </Tooltip>
          )}
          <CancelButton
            showcomplete={savedTaskClicked}
            onClick={cancel}
            value="Clear"
            type="reset"
            data-testid="CancelButton"
          >
            Cancel
          </CancelButton>
        </SubmitButtonContainer>
      );
    } else {
      return (
        <CompleteButtonTooltipContainer finished showcomplete={savedTaskClicked}>
          <CompleteButton
            finished
            variant="contained"
            disableElevation
            showcomplete={savedTaskClicked}
            onClick={cancel}
            data-testid="FinishedButton"
          >
            Finished
          </CompleteButton>
        </CompleteButtonTooltipContainer>
      );
    }
  };

  const returnActivityList = () => {
    if (savedTaskClicked) {
      const taskActivities = taskDetail?.activities ?? selectedTask?.activities;
      return (
        <>
          <ActivitiesTextHeader>Activities</ActivitiesTextHeader>
          <ActivityList
            ngm={ngm}
            assignOptions={assignOptions}
            setViewActivity={setViewActivity}
            listOfActivities={taskActivities}
            setSelectedActivity={setSelectedActivity}
          />
        </>
      );
    }
  };

  if (isTaskDetailsPending || !enums) {
    return 'Loading...';
  }

  const returnTask = () => {
    return (
      <>
        {modal ? (
          <Modal
            enums={enums}
            setNewTaskClicked={setNewTaskClicked}
            setSavedTaskClicked={setSavedTaskClicked}
            display={modal}
            setModal={setModal}
            closeReasonPicked={closeReasonPicked}
            isCompleteTaskPending={isCompleteTaskPending}
          />
        ) : (
          ''
        )}

        {hiFriendMessage ? (
          <HiFriendModal data-testid="HiFriendDisplay">
            <HiFriendContent>
              No user found in tenant, perhaps they are in HiFriend or pending enrollment, please check HiFriend
              network.
            </HiFriendContent>
            <HiFriendDismissButton onClick={hiFriendDimissal}>OK</HiFriendDismissButton>
          </HiFriendModal>
        ) : (
          ''
        )}

        <HeaderContainer>
          <TextHeader>
            {selectedTask?.status === 'COMPLETE'
              ? 'Completed Task'
              : savedTaskClicked
              ? 'Edit Task'
              : 'Create New Task'}
          </TextHeader>
        </HeaderContainer>
        <CreateTaskDataContainer data-testid="CreateTaskDataContainer">
          {selectedTask?.sourceType === 'CARD' ? (
            <CardPreviewContainer>
              <GreetingCard cardId={selectedTask.sourceId} maxSize={'200px'} cardFrom={memberTaskInfo?.firstName} />
            </CardPreviewContainer>
          ) : null}
          <CallContainer>
            {!ngm && (
              <CallLog
                taskDetail={taskDetail}
                selectedTask={selectedTask}
                userId={userId}
                ngmId={ngmId}
                callLogInfo={callLogInfo}
                organizationId={organizationId}
                taskSaved={savedTaskClicked}
                setSelectedTask={setSelectedTask}
                setCallLogInfo={setCallLogInfo}
                taskUpdatePending={isEditTaskPending}
              />
            )}
            <PeersCallButton userId={userId} ngmId={ngmId} organizationId={organizationId} />
          </CallContainer>
          <form data-testid="TaskForm" onSubmit={handleSubmit(onSubmit)} style={{ marginTop: '-10px' }}>
            <DataColumn top>
              <div>
                <SectionHeader id="TaskTypeLabel">Task type:</SectionHeader>
                <SelectStyle
                  {...register('TaskType')}
                  type="text"
                  id="TaskType"
                  aria-labelledby="TaskTypeLabel"
                  data-testid={savedTaskClicked ? 'TaskTypeInputEdit' : 'TaskTypeInputCreate'}
                  disabled={savedTaskClicked || selectedTask?.status === 'COMPLETE'}
                  onChange={(e) => setCurrentSelectedTaskType(e.currentTarget.value)}
                  value={taskDetail ? taskDetail.taskType : selectedTask?.taskType}
                >
                  {enums ? (
                    enums.TaskType.map((data) => {
                      if (shouldTaskBeDisplayed(data.code)) {
                        return (
                          <option key={data.code} value={data.code}>
                            {data.displayName}
                          </option>
                        );
                      }
                    })
                  ) : (
                    <option
                      key="TaskTypeOptionLoading"
                      data-testid="LoadingTaskType"
                      value={`Loading`}
                    >{`Loading`}</option>
                  )}
                </SelectStyle>
              </div>
              <div style={{ marginRight: '40px' }} />
              <div>
                <SectionHeader>Due Date:</SectionHeader>
                <InputStyle
                  {...register('DueDate', { required: true, validate: (v) => v !== null || undefined || '' })}
                  dueDate
                  type="date"
                  id="DueDate"
                  disabled={selectedTask?.status === 'COMPLETE'}
                  data-testid="DueDateInput"
                  defaultValue={defaultDueDate}
                  error={formState.errors.DueDate}
                />
              </div>
              {savedTaskClicked ? (
                <div>
                  <SectionHeader>Task Status:</SectionHeader>
                  <div> {taskDetail ? taskDetail.status : selectedTask ? selectedTask?.status : ''}</div>
                </div>
              ) : (
                ''
              )}
              <div style={{ marginRight: '120px' }} />
              {savedTaskClicked ? (
                <div>
                  <SectionHeader>Priority:</SectionHeader>
                  <div> {selectedTask ? selectedTask.priority : ''}</div>
                </div>
              ) : (
                ''
              )}
            </DataColumn>
            <div style={{ marginTop: '20px' }} />
            <DataColumn>
              {currentSelectedTaskType === 'ANDY' || selectedTask?.taskType === 'ANDY' ? (
                <>
                  <div>
                    <SectionHeader id="TaskSubtypeLabel">Task Subtype:</SectionHeader>
                    <SelectStyle
                      {...register('TaskSubtype')}
                      type="text"
                      id="TaskSubtype"
                      aria-labelledby="TaskSubtypeLabel"
                      data-testid={savedTaskClicked ? 'TaskSubtypeInputEdit' : 'TaskSubtypeInputCreate'}
                      disabled={savedTaskClicked || selectedTask?.status === 'COMPLETE'}
                      value={currentSelectedTaskSubType}
                      onChange={(e) => setCurrentSelectedTaskSubType(e.target.value)}
                    >
                      {enums ? (
                        enums.TaskSubType.map((data) => {
                          return <option key={data.code} value={`${data.code}`}>{`${data.displayName}`}</option>;
                        })
                      ) : (
                        <option
                          key="TaskSubTypeOptionLoading"
                          data-testid="TaskSubType"
                          value={`Loading`}
                        >{`Loading`}</option>
                      )}
                    </SelectStyle>
                  </div>
                  <div style={{ marginRight: '40px' }} />
                </>
              ) : (
                ''
              )}

              <div>
                <SectionHeader>Assign to</SectionHeader>
                <SelectStyle
                  {...register('Assign')}
                  disabled={selectedTask?.status === 'COMPLETE'}
                  type="text"
                  id="Assign"
                  data-testid="AssignInput"
                  onChange={(e) => setAssignedTo(e.currentTarget.value)}
                  value={assignedTo || ''}
                >
                  {assignOptions ? (
                    <>
                      <option key="AssignInputInfoOptionDefault" data-testid="AssignInputInfo_0" value="">
                        Unassigned
                      </option>
                      {assignOptions?.map((data, i) => {
                        return (
                          <option
                            data-testid={`AssignInputInfo_${data.userId}`}
                            key={`AssignInputInfo_${i}`}
                            value={`${data.userId}`}
                          >{`${data.name}`}</option>
                        );
                      })}
                      {taskDetail?.assignedToUserId &&
                      !assignOptions?.some((x) => x.userId === taskDetail?.assignedToUserId) ? (
                        <option
                          hidden
                          key="PlaceHolderOption"
                          data-testid="AssignInputInfo_Placeholder"
                          value={taskDetail?.assignedToUserId}
                        >
                          {taskDetail?.assignedToName}
                        </option>
                      ) : null}
                    </>
                  ) : (
                    <option key="AssignInputInfoOptionLoading" data-testid="LoadingAssignType" value="Loading">
                      Loading
                    </option>
                  )}
                </SelectStyle>
              </div>
              <div style={{ marginRight: '40px' }} />
              {savedTaskClicked && selectedTask?.taskScreeningGuids?.length > 0 ? (
                <div>
                  <SectionHeader>Screenings:</SectionHeader>
                  <div>
                    {' '}
                    {selectedTask
                      ? `${selectedTask.taskScreeningGuids.map((x) => screeningNamesShortened[x]).join(', ')}`
                      : ''}
                  </div>
                </div>
              ) : (
                ''
              )}
            </DataColumn>
            <DataColumn>
              <div>
                <SectionHeader>Notes:</SectionHeader>
                <TextAreaStyle
                  {...register('Notes')}
                  readOnly={selectedTask?.status === 'COMPLETE'}
                  type="textarea"
                  id="Notes"
                  data-testid="NotesInput"
                />
              </div>
            </DataColumn>
            {displayButtons()}
            <ErrorMessage savedTaskClicked={savedTaskClicked} data-testid="ErrorMessage">
              {createTaskErrorMessage || editTaskErrorMessage || taskErrorMessage
                ? parseError(
                    createTaskErrorMessage
                      ? createTaskErrorMessage
                      : taskErrorMessage
                      ? taskErrorMessage
                      : editTaskErrorMessage,
                  )
                : ''}
            </ErrorMessage>
            {formState.errors.DueDate && (
              <ErrorMessage data-testid="NoDateError">Please enter a valid due date.</ErrorMessage>
            )}
          </form>
        </CreateTaskDataContainer>

        {returnActivityList()}
      </>
    );
  };

  return (
    <Routes>
      <Route
        path="activity/*"
        element={
          <AddActivity
            ngm={ngm}
            taskSaved={taskSaved}
            values={values}
            viewActivity={viewActivity}
            setViewActivity={setViewActivity}
            memberInfo={memberInfo}
            memberTaskInfo={memberTaskInfo}
            savedTaskData={selectedTask}
            enums={enums}
            thriveStatus={thriveStatus}
            activitySelected={selectedActivity ?? []}
            setSelectedTask={setSelectedTask}
          />
        }
      />
      <Route index element={returnTask()} />
    </Routes>
  );
};

CreateTask.propTypes = {
  setNewTaskClicked: PropTypes.func,
  isSavedTask: PropTypes.bool,
  setSavedTaskClicked: PropTypes.func,
  completed: PropTypes.bool,
  ngm: PropTypes.bool,
  memberTaskInfo: PropTypes.object,
  savedTaskData: PropTypes.object,
  onChange: PropTypes.func,
};

export default CreateTask;
